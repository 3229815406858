<template>
  <div class="min-vh-60 d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :md="9" :lg="7" :xl="6">
          <CCard class="mx-4">
            <CCardHeader>
              <h1>Change Password</h1>
            </CCardHeader>
            <CCardBody class="p-4">
              <CInputGroup class="mb-3">
                <CInputGroupText>
                  <CIcon icon="cil-lock-locked" />
                </CInputGroupText>
                <CFormInput
                  type="password"
                  placeholder="Current Password"
                  v-model="oldPassword"
                />
              </CInputGroup>

              <CInputGroup class="mb-3">
                <CInputGroupText>
                  <CIcon icon="cil-lock-locked" />
                </CInputGroupText>
                <CFormInput
                  type="password"
                  placeholder="New Password"
                  v-model="newPassword"
                />
              </CInputGroup>

              <CRow align="right">
                <CCol :xs="6"></CCol>
                <CCol :xs="6" align="right">
                  <CButton
                    color="info"
                    @click="onChangePassword"
                    class="white-text"
                  >
                    Change Password
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
            <div
              v-if="successMessage"
              class="alert alert-success"
              align="center"
            >
              {{ successMessage }}
            </div>
            <div v-if="errorMessage" class="alert-failure" align="right">
              {{ errorMessage }}
            </div>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import {
  CCard,
  CCardBody,
  CContainer,
  CRow,
  CCol,
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CButton,
} from '@coreui/vue'
import { CIcon } from '@coreui/icons-vue'
import HTTPService from '../services/HTTPService'

export default {
  name: 'ChangePassword',
  components: {
    CCard,
    CCardBody,
    CContainer,
    CRow,
    CCol,
    CInputGroup,
    CInputGroupText,
    CFormInput,
    CButton,
    CIcon,
  },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      successMessage: '',
      errorMessage: '',
    }
  },
  methods: {
    async onChangePassword() {
      // Call the change_password endpoint with the old and new passwords

      try {
        const password = this.oldPassword
        const newPassword = this.newPassword
        const response = await HTTPService.changePassword(password, newPassword)

        //console.log(response)

        if (response.status === 200) {
          // handle success, e.g. display a success message
          this.successMessage = 'Password Changed Successfully!'
          this.errorMessage = ''
        }
      } catch (error) {
        this.errorMessage = 'Failed to change Password:' + error.response
        this.successMessage = ''
        //console.log(error.response)
      }
    },
    OnCancel() {
      this.$router.push({ name: 'Upload' })
    },
  },
}
</script>
<style>
.alert-failure {
  background-color: #f8d7da;
  color: #721c24;
  border-color: #f5c6cb;
  align: 'center';
}
</style>
