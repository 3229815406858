<template>
  <div class="perplexica-wrapper">
    <div v-if="!perplexicaUrl" class="loading">Loading...</div>
    <iframe
      v-else
      :src="perplexicaUrl"
      width="100%"
      height="100%"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'SearchChat',
  data() {
    return {
      perplexicaUrl: null,
    }
  },
  created() {
    this.perplexicaUrl = process.env.VUE_APP_PERPLEXICA_URL
  },
}
</script>

<style scoped>
.perplexica-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

iframe {
  flex-grow: 1;
  border: none;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.5rem;
}
</style>
