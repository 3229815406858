<template>
  <div class="container">
    <div class="top-row">
      <div class="model-selector">
        <select
          id="modelSelector"
          v-model="selectedModel"
          @change="onModelChange"
        >
          <option value="gpt-4o">ChatGPT-4o</option>
          <option value="claude-3-5-sonnet">Claude 3.5 Sonnet</option>
        </select>
      </div>
    </div>
    <div class="chatbot-input">
      <div class="message-area">
        <textarea
          v-model="userInput"
          placeholder="入力してください"
          @keydown.enter.prevent="handleEnterKey"
          @input="autoResize"
          spellcheck="false"
        ></textarea>
        <button
          class="copy-button"
          @click="copyContent"
          :disabled="userInput === ''"
        >
          <CIcon :icon="cilCopy" />
        </button>
      </div>
      <div class="result-area">
        <div
          class="result"
          ref="chatbotResult"
          v-html="parseMarkdown(resultOutput)"
        ></div>
      </div>
    </div>
    <div class="system-prompt-container">
      <div class="system-prompt-label" @click="togglePromptCollapse">
        <span class="toggle-icon" :class="{ expanded: !isPromptCollapsed }"
          >▶</span
        >
        {{ getPromptLabel }}
      </div>
      <div v-if="!isPromptCollapsed" class="prompt-input">
        <input
          id="system-prompt"
          type="text"
          v-model="promptValue"
          @keydown.enter.prevent="correctText"
          spellcheck="false"
        />
      </div>
    </div>
    <div class="generate-button-container">
      <button
        class="generate-button"
        @click="correctText"
        :disabled="isProcessing || !userInput"
        :class="{ generating: isProcessing }"
      >
        実行
      </button>
    </div>
    <!-- Overlay for processing message -->
    <ProcessingOverlay :show="isProcessing" />
    <ErrorPopup
      :show="showErrorPopup"
      :message="errorMessage"
      @close="closeErrorPopup"
    />
  </div>
</template>

<script>
import DOMPurify from 'isomorphic-dompurify'
import { marked } from 'marked'
import { cilCopy } from '@coreui/icons'
import ProcessingOverlay from '@/components/ProcessingOverlay.vue'
import ErrorPopup from '@/components/ErrorPopup.vue'
export default {
  components: {
    ProcessingOverlay,
    ErrorPopup,
  },
  data() {
    return {
      cilCopy,
      selectedModel: 'gpt-4o',
      promptLabelText: '追加コンテキスト - Optional',
      isPromptCollapsed: true,
      autoCorrect: false,
      isProcessing: false,
      isStreaming: false,
      showErrorPopup: false,
      errorMessage: '',
      promptValue: '',
      userInput: '',
      resultOutput: '',
    }
  },
  computed: {
    getPromptLabel() {
      return this.promptLabelText
    },
  },
  methods: {
    onModelChange() {
      console.log('Selected model:', this.selectedModel)
    },
    togglePromptCollapse() {
      this.isPromptCollapsed = !this.isPromptCollapsed
    },
    async correctText() {
      // Implementation for text correction
      if (!this.userInput || this.isStreaming || this.isProcessing) return

      const data = {
        user_input: this.userInput,
        prompt: this.promptValue,
        model: this.selectedModel,
      }

      this.resultOutput = ''
      this.isProcessing = true
      this.isStreaming = true

      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/correct-text`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'text/event-stream',
            },
            body: JSON.stringify(data),
          },
        )

        this.isProcessing = false

        if (!response.ok) {
          const errorBody = await response.json()
          this.errorMessage =
            errorBody.error ||
            `HTTP error! status: ${response.status} ${response.statusText}`
          this.showErrorPopup = true
          throw new Error(this.errorMessage)
        }

        const reader = response.body.getReader()
        const decoder = new TextDecoder()

        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read()
          if (done) break

          const chunk = decoder.decode(value, { stream: true })
          const lines = chunk.split('\n').filter(Boolean)

          for (const line of lines) {
            if (line.startsWith('data:')) {
              const data = line.slice(5)
              if (data === '[DONE]') {
                // Streaming is complete
                console.log('Streaming complete')
              } else {
                this.resultOutput += `${data.slice(1)}`
                this.$refs.chatbotResult.scrollTop =
                  this.$refs.chatbotResult.scrollHeight
              }
            }
          }
        }
      } catch (error) {
        console.error('Error correct text:', error.response.data.error)
        if (error.response && error.response.data.error) {
          this.errorMessage = error.response.data.error
          this.showErrorPopup = true
        }
      } finally {
        this.isProcessing = false
        this.isStreaming = false
      }
    },
    copyContent() {
      navigator.clipboard.writeText(this.userInput)
    },
    parseMarkdown(text) {
      if (typeof text !== 'string') {
        return '' // Return the text as is if it's not a string
      }

      text = text.replaceAll('\\n', '\n')
      text = text.replaceAll('```markdown', '```')
      try {
        const renderer = new marked.Renderer()
        renderer.link = function (href, title, text) {
          // Renderer() seems to return different parameters depending on the version of marked installed...
          const link = {
            href: href.href ?? href,
            text: href.text ?? text,
          }
          return (
            '<a target="_blank" href="' + link.href + '">' + link.text + '</a>'
          )
        }

        text = text.replaceAll('```', '')
        let parsed = marked(text, { renderer: renderer })

        // format Bing's source links more nicely
        // 1. replace "[^1^]" with "[1]" (during progress streams)
        parsed = parsed.replace(/\[\^(\d+)\^]/g, '<strong>[$1]</strong>')
        // 2. replace "^1^" with "[1]" (after the progress stream is done)
        parsed = parsed.replace(/\^(\d+)\^/g, '<strong>[$1]</strong>')

        // Allow the iframe to show the images created by Bing Image Creator.
        return DOMPurify.sanitize(parsed, {
          ADD_TAGS: ['iframe'],
          ADD_ATTR: [
            'allow',
            'allowfullscreen',
            'frameborder',
            'scrolling',
            'srcdoc',
            'target',
          ],
        })
      } catch (err) {
        console.error('ERROR', err)
        return null
      }
    },
    handleEnterKey(event) {
      if (event.shiftKey) {
        // Allow Shift + Enter to create a new line
        const textarea = event.target
        const cursorPosition = textarea.selectionStart
        this.userInput =
          this.userInput.slice(0, cursorPosition) +
          '\n' +
          this.userInput.slice(cursorPosition)
        this.$nextTick(() => {
          textarea.selectionStart = cursorPosition + 1
          textarea.selectionEnd = cursorPosition + 1
        })
      } else {
        // Handle normal Enter keypress
        this.correctText()
      }
    },
    closeErrorPopup() {
      this.showErrorPopup = false
      this.errorMessage = ''
    },
  },
}
</script>

<style scoped>
.container {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border: 3px solid #001d61;
  border-radius: 10px;
  background-color: rgb(240, 245, 245);
}

.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.model-selector {
  width: 50%;
}

.model-selector select {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #000;
  font-size: 14px;
  height: 30px;
}

.auto-correct {
  display: flex;
  align-items: center;
}

.auto-correct span {
  margin-left: 5px;
  font-size: 18px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 49px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #ff8c00;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.chatbot-input {
  display: flex;
  flex: 1;
  margin-bottom: 15px;
  min-height: 0; /* This is crucial for nested flex containers */
}

.message-area,
.result-area {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(218, 223, 227);
  border-radius: 5px;
  min-height: 0;
  font-family: inherit; /* Ensure consistent font family */
  color: inherit; /* Ensure consistent text color */
  font-size: 14px; /* Ensure consistent font size */
}

.message-area {
  width: 60%;
  margin-right: 10px;
  position: relative;
}

.result-area {
  width: 40%;
  position: relative;
  overflow: hidden;
}

.message-area textarea,
.result-area .result {
  width: 100%;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #fff;
  font-family: inherit;
  color: inherit;
  font-size: 14px;
}

.message-area textarea {
  height: 100%;
  resize: none;
}

.result-area .result {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}

.copy-button {
  position: absolute;
  bottom: 5px;
  right: 20px;
  padding: 5px 10px;
  background-color: none;
  border: 0px;
  border-radius: 3px;
  cursor: pointer;
}

.copy-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.copy-button:hover {
  background-color: #e0e0e0;
}

.system-prompt-container {
  margin-bottom: 15px;
}

.system-prompt-label {
  font-weight: bold;
  margin: 0 0 5px 5px;
  font-size: 14px;
  cursor: pointer;
}

.toggle-icon {
  display: inline-block;
  margin-right: 5px;
  transition: transform 0.3s ease;
}

.toggle-icon.expanded {
  transform: rotate(90deg);
}

.prompt-input {
  display: flex;
  align-items: center;
}

.prompt-input input {
  flex: 1;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.generate-button-container {
  display: flex;
  justify-content: flex-end;
}

.generate-button {
  padding: 10px 20px;
  background-color: #001d61;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.generate-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.generate-button:hover .upload-button:hover {
  background-color: #002b8f;
}

.generate-button.generating {
  background-color: #808080;
  cursor: not-allowed;
}

.processing-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.processing-message {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.loading-icon-wrapper {
  margin-bottom: 10px;
}

.loading-icon {
  width: 50px;
  height: 50px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
</style>
