<template>
  <div v-if="show" class="popup">
    <div class="popup-content">
      <p>{{ message }}</p>
      <div class="popup-buttons">
        <button @click="onClose" class="btn btn-info white-text">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorPopup',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      required: true,
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
}

.popup-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
