<template>
  <div class="container-fluid">
    <CCard class="faq">
      <CCardHeader>
        <h2>News and Updates</h2>
      </CCardHeader>
      <CCardBody>
        <h4 class="question">Release 0.1.6 - 29-05-2023</h4>
        <p class="answer">
          Added multimedia support for audio and video files:
        </p>
        <div align="left">
          <ul>
            <li>M4A - Apple Lossless Audio Codec (.m4a)</li>
            <li>MP3 - MPEG Audio Layer III (.mp3)</li>
            <li>WEBM - WebM Audiovisual Media (.webm)</li>
            <li>MP4 - MPEG-4 Part 14 (.mp4)</li>
            <li>MPGA - MPEG Audio Stream (.mpga)</li>
            <li>WAV - Waveform Audio File Format (.wav)</li>
            <li>MPEG - Moving Picture Experts Group (.mpeg)</li>
            <li>MOV - QuickTime File Format (.mov)</li>
            <li>FLAC - Free Lossless Audio Codec (.flac)</li>
          </ul>
        </div>
        <br />
        <p class="answer">
          Now you can upload (max 25MB file size for each file type). The
          process is at 16X speed so for every 10 minutes takes around 40
          seconds to process.
        </p>
      </CCardBody>
      <CCardBody>
        <h4 class="question">Release 0.1.5 - 27-05-2023</h4>
        <p class="answer">
          Added Youtube support! Now you can create a chatbot out of your
          favourite Youtube videos. Coming soon, a wide variety of audio and
          video formats.
        </p>
      </CCardBody>
      <CCardBody>
        <h4 class="question">Release 0.1.4 - 24-05-2023</h4>
        <p class="answer">
          Added Whitelabelling functionality. You can now design and configure
          your chatbot's appearance with Product Logo, Assistant name,
          background colors, font colors etc. Try it out!
        </p>
      </CCardBody>
      <CCardBody>
        <h4 class="question">Release 0.1.3 - 20-05-2023</h4>
        <p class="answer">Added additional file formats. We now support:</p>
        <div align="left">
          <ul>
            <li>Word (.docx)</li>
            <li>Powerpoint (.pptx)</li>
            <li>Excel (.xlsx)</li>
            <li>HTML (.html)</li>
            <li>Emails (.msg)</li>
            <li>Images (.png, .svg)</li>
          </ul>
          <ul>
            <li>Text (.txt)</li>
            <li>CSV (.csv)</li>
            <li>PDF (.pdf)</li>
            <li>Markdown (.md)</li>
            <li>JPG (.jpg, .jpeg)</li>
          </ul>
        </div>
      </CCardBody>

      <CCardBody>
        <h4 class="question">Release 0.1.2 - 14-05-2023</h4>
        <p class="answer">Added Web Crawling and Url Selection</p>
      </CCardBody>

      <CCardBody>
        <h4 class="question">Release 0.1.1 - 07-05-2023</h4>
        <p class="answer">Additional file types supported</p>
      </CCardBody>

      <CCardBody>
        <h4 class="question">Release 0.1.0 - 01-05-2023</h4>
        <p class="answer">We released support for PDF images.</p>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
export default {
  name: 'News',
  data() {
    return {}
  },
  components: {},
}
</script>
<style scoped>
.jumbotron {
  background-color: #f8f9fa;
  padding: 50px;
}

.card-header {
  background-color: #f8f9fa;
  font-weight: 700;
  font-size: 22px;
}

.card-body {
  font-size: 18px;
}

.card {
  margin-bottom: 30px;
  border: none;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

ul {
  padding-left: 20px;
  margin-bottom: 0;
}

h1,
h2,
h4 {
  font-weight: 700;
  margin-bottom: 20px;
}

.container-fluid {
  padding: 30px;
}
</style>
