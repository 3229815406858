<template>
  <div ref="loginTemplate" class="login-body">
    <div class="login-template">
      <p
        style="
          text-align: center;
          font-size: 50px;
          color: #0c2231;
          font-weight: bold;
        "
      >
        Login
      </p>
      <CContainer>
        <CRow class="justify-content-center">
          <CCol :md="9" :lg="7" :xl="6">
            <CCard class="mx-4">
              <p class="text-medium-emphasis"></p>

              <div class="login-inputs" style="margin-top: 20px">
                <input
                  class="login-input"
                  placeholder="Email"
                  autocomplete="email"
                  v-model="login.email"
                  @keyup.enter="OnLogin"
                />
                <input
                  class="login-input"
                  type="password"
                  placeholder="Password"
                  autocomplete="current-password"
                  v-model="login.password"
                  @keyup.enter="OnLogin"
                />
                <label class="checkbox-container">
                  Stay Logged In
                  <input
                    type="checkbox"
                    ref="stayLoggedIn"
                    style="margin-left: 5px"
                  />
                  <!-- <span class="checkmark"></span> -->
                </label>
              </div>
              <div class="login-buttons">
                <CButton color="info" @click="OnLogin" class="login-button"
                  >Login</CButton
                >
                <a href="#" class="forgot-password-link" @click="OnReset"
                  >Forgot Password?</a
                >
                <a
                  href="#"
                  class="register-link"
                  @click="OnRegister"
                  style="font-weight: bold; margin-bottom: 10px"
                  >Don't have an account? Click here to Register!</a
                >
              </div>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </div>
  <div class="warning-canvas" v-show="showWarning">
    <h1>Please confirm your email address</h1>
    <p>
      To finalize the Registration process, you need to confirm the registration
      via the email address provided. Please check your inbox or spam email
      folder for the registration email and follow the instructions. If you
      can't find that email, click on "Resend Confirmation Emai" below.
    </p>
    <div class="warning-canvas-layout-button">
      <CButton color="info" @click="onCancel">Cancel</CButton>
      <CButton color="info" @click="onResend"
        >Resend Confirmation Email</CButton
      >
    </div>
  </div>
  <div class="warning-canvas" v-show="showFault">
    <h1 class="fault-text">{{ statusFaultMessage }}</h1>
    <p>{{ faultMessage }}</p>
    <div class="warning-canvas-layout-button">
      <CButton color="info" class="primary" @click="onCancel">OK</CButton>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import HTTPService from '../services/HTTPService'
import { required, email } from '@vuelidate/validators'
export default {
  name: 'Login',
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      login: {
        email: '',
        password: '',
      },
      status: '',
      showWarning: false,
      showFault: false,
      statusFaultMessage: '',
      faultMessage: '',
      error: false,
    }
  },
  validations() {
    return {
      login: {
        email: { required, email },
        password: { required },
      },
    }
  },
  methods: {
    async OnLogin() {
      if (this.v$.$invalid) {
        this.error = true
      } else {
        this.error = false
        var response = await HTTPService.login(
          this.login.email,
          this.login.password,
        )
        if (response.data.token) {
          if (this.$refs.stayLoggedIn.checked)
            localStorage.setItem('token', response.data.token)
          else sessionStorage.setItem('token', response.data.token)

          // make an async call to upload to ping the AI server running the upload method.
          // setTimeout(() => {
          //   HTTPService.upload(null, null).then((response) => {
          //     console.log('finished dummy call')
          //     console.log(response.data)
          //   })
          // }, 0)
          this.$emit('change-active-component', {
            component: 'ChatbotDashboard',
          })
          this.$router.push('/poc')
          //console.log('finished login call')
        } else if (response.data.message) {
          if (response.data.message == 'User login unsuccessful') {
            this.WrongPassword()
          } else {
            this.showWarning = true
            this.$refs.loginTemplate.classList.add('bg-blur')
          }
        } else {
          this.error = true
          this.WrongPassword()
        }
      }
    },
    WrongPassword() {
      this.showFault = true
      this.statusFaultMessage = 'Login Failed'
      this.faultMessage = 'Incorrect email address or password'
      this.$refs.loginTemplate.classList.add('bg-blur')
    },
    OnRegister() {
      this.$emit('change-active-component', {
        component: 'Register',
      })
      // this.$router.push({ name: 'Register' })
    },
    OnReset() {
      //console.log('reset')
      // this.$router.push({ name: 'ResetPassword' })
      this.$emit('change-active-component', {
        component: 'ResetPassword',
      })
    },
    onCancel() {
      this.$refs.loginTemplate.classList.remove('bg-blur')
      this.showWarning = false
      this.showFault = false
    },
    onResend() {
      let body = {
        email: this.login.email,
        password: this.login.password,
      }
      HTTPService.sendconfirmemail(body).then(() => {
        this.onCancel()
      })
    },
  },
}
</script>

<style scoped>
.login-header {
  text-align: center;
  font-size: 30px;
  color: #ffffff;
  font-weight: bold;
}

.login-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-input {
  width: 80%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.checkbox-container {
  margin: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.checkmark {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.checkmark:before {
  content: '';
  display: block;
  width: 10px;
  height: 5px;
  border-bottom: 2px solid #fff;
  border-right: 2pxsolid #fff;
  transform: rotate(-45deg);
  margin-left: 5px;
}

.login-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-button {
  margin-top: 20px;
  width: 80%;
  color: white; /* Add this line to make the button text white */
}

.forgot-password-link {
  margin-top: 10px;
  font-size: 14px;
  color: #0c2231;
  text-decoration: none;
}

.register-link {
  margin-top: 10px;
  font-size: 14px;
  color: #0c2231;
  text-decoration: none;
}

.warning-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.warning-canvas h1 {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}

.warning-canvas p {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
}

.warning-canvas-layout-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.warning-canvas-layout-button CButton {
  margin-right: 10px;
}
</style>
