<template>
  <CContainer>
    <p
      style="
        text-align: center;
        font-size: 50px;
        color: #0c2231;
        font-weight: bold;
        white-space: pre-line;
      "
    >
      MISI POC
    </p>
    <CCard class="selection">
      <CCol>
        <CRow
          v-for="feature in features"
          :key="feature.route"
          class="d-flex justify-content-center"
        >
          <CButton @click="$router.push(feature.route)" class="function-button">
            {{ feature.title }}
          </CButton>
        </CRow>
      </CCol>
    </CCard>
  </CContainer>
</template>

<script>
export default {
  data() {
    return {
      features: [
        { route: '/poc/main-chat', title: '基本チャット機能' },
        { route: '/poc/search-chat', title: '検索チャット機能' },
        { route: '/poc/summary', title: '要約機能' },
        { route: '/poc/image-generation', title: '画像生成機能' },
        { route: '/poc/meeting-transcription', title: '議事録作成機能' },
      ],
    }
  },
}
</script>

<style scoped>
.function-button {
  width: 20rem;
  margin: 10px auto;
  color: #0c2231;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  border: none;
}

.function-button:hover {
  outline: 2px solid #0c2231;
  border-radius: 8px;
}

.selection {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>
