<template>
  <div class="container-fluid">
    <CCard class="privacy">
      <CCardHeader>
        <h2>Privacy Policy</h2>
      </CCardHeader>
      <CCardBody>
        For any enquiries about our Privacy Policy contact us at
        support@chatbotanything.com
        <br />
        <br />

        <h4 class="question">Information we collect</h4>
        <p class="answer">
          When you create an account with us, we collect your name, email
          address, and password. We also collect information about the chatbots
          you create and the files you upload to our website. This may include
          personal files, PDFs, Word documents, and personal websites.
        </p>

        <h4 class="question">How we use your information</h4>

        <p class="answer">
          We use your information to provide you with access to our website and
          to improve the services we offer. We may use your email address to
          send you newsletters, updates, or promotional messages about our
          services. We may also use your information to respond to your
          inquiries or requests.
        </p>

        <h4 class="question">How we protect your information</h4>
        <p class="answer">
          We use industry-standard security measures to protect your personal
          information. We have implemented technical and organizational measures
          to prevent the loss, misuse, unauthorized access, disclosure, or
          alteration of your personal information. We also restrict access to
          your personal information to our employees who need to know that
          information to provide you with our services.
        </p>

        <h4 class="question">Sharing of information</h4>
        <p class="answer">
          We do not sell, rent, or share your personal information with third
          parties without your consent, except as required by law. We may share
          your personal information with our service providers who assist us in
          providing you with our services, but only to the extent necessary to
          provide those services.
        </p>
        <h4 class="question">Your rights</h4>
        <p class="answer">
          You have the right to access, correct, or delete your personal
          information at any time. You may also withdraw your consent to our use
          of your personal information. To exercise your rights, please contact
          us at the email address provided below.
        </p>

        <h4 class="question">Changes to this Privacy Policy</h4>
        <p class="answer">
          We may update this Privacy Policy from time to time. Any changes we
          make to this Privacy Policy will be posted on our website. We
          encourage you to review this Privacy Policy periodically to stay
          informed about our privacy practices.
        </p>

        <h4 class="question">Contact us</h4>
        <p class="answer">
          If you have any questions or concerns about this Privacy Policy,
          please contact us at support@chatbotanything.com
        </p>
        <h4 class="question">General Data Protection Regulation (GDPR)</h4>
        <p class="answer">
          Under the General Data Protection Regulation (GDPR), users have
          certain data protection rights with respect to their personal
          information. These rights include:
        </p>

        <ul>
          <li>
            The right to access: You have the right to obtain a copy of your
            personal information and to know how it is being processed.
          </li>
          <li>
            The right to rectification: You have the right to have any
            inaccurate or incomplete personal information corrected.
          </li>
          <li>
            The right to erasure: You have the right to have your personal
            information deleted, subject to certain exceptions.
          </li>
          <li>
            The right to restrict processing: You have the right to request that
            we limit the processing of your personal information, subject to
            certain exceptions.
          </li>
          <li>
            The right to data portability: You have the right to receive a copy
            of your personal information in a structured, machine-readable
            format and to transfer that information to another controller.
          </li>
          <li>
            The right to object: You have the right to object to the processing
            of your personal information, subject to certain exceptions.
          </li>
        </ul>

        <p>
          To exercise your data protection rights, please contact us at the
          email address provided in this Privacy Policy. We will respond to your
          request as soon as possible and in accordance with applicable law.
          Please note that we may need to verify your identity before we can
          fulfill your request.
        </p>
        <h4 class="question">California Privacy Protection Act (CalOPPA)</h4>
        <p class="answer">
          Our Service complies with the California Privacy Protection Act
          (CalOPPA) and we are committed to protecting the privacy rights of
          California residents. Under CalOPPA, California residents have the
          right to:
        </p>

        <ul>
          <li>Know what personal information is being collected about them.</li>
          <li>
            Know whether their personal information is being sold or disclosed,
            and to whom.
          </li>
          <li>Request that their personal information be deleted.</li>
          <li>Opt-out of the sale of their personal information.</li>
          <li>
            Not be discriminated against for exercising their privacy rights.
          </li>
        </ul>
        <p>
          If you are a California resident and would like to exercise your
          privacy rights, please contact us at the email address provided in
          this Privacy Policy. We will respond to your request as soon as
          possible and in accordance with applicable law. Please note that we
          may need to verify your identity before we can fulfill your request.
          We do not sell or disclose personal information of California
          residents to third parties for their marketing purposes without your
          consent. We also provide an option for California residents to opt-out
          of the sale of their personal information. If you would like to
          exercise this right, please contact us at the email address provided
          in this Privacy Policy. We will not discriminate against California
          residents who exercise their privacy rights. If you exercise your
          privacy rights, we will not deny you access to our services, charge
          you different prices or rates, or provide you with a different level
          or quality of service.
        </p>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
export default {
  name: 'Privacy',
  data() {
    return {}
  },
  components: {},
}
</script>
<style scoped>
.jumbotron {
  background-color: #f8f9fa;
  padding: 50px;
}

.card-header {
  background-color: #f8f9fa;
  font-weight: 700;
  font-size: 22px;
}

.card-body {
  font-size: 18px;
}

.card {
  margin-bottom: 30px;
  border: none;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

ul {
  padding-left: 20px;
  margin-bottom: 0;
}

h1,
h2,
h4 {
  font-weight: 700;
  margin-bottom: 20px;
}

.container-fluid {
  padding: 30px;
}
</style>
