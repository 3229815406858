<template>
  <div class="container">
    <div class="selected-image-row">
      <div class="selected-image" v-if="selectedImage !== null">
        <template v-if="images[selectedImage].isDefault">
          <CIcon
            :icon="cilImage"
            size="xxl"
            style="width: 200px; height: 200px; color: #666"
          />
        </template>
        <img
          v-else
          :src="images[selectedImage].thumbnail"
          :alt="images[selectedImage].alt"
        />
      </div>
    </div>
    <div class="thumbnail-row">
      <div
        v-for="(image, index) in images"
        :key="index"
        class="thumbnail"
        :class="{ selected: selectedImage === index }"
        @click="selectImage(index)"
      >
        <template v-if="image.isDefault">
          <CIcon :icon="cilImage" size="xl" />
        </template>
        <img v-else :src="image.thumbnail" :alt="image.alt" />
      </div>
    </div>
    <div class="input-row">
      <div class="label-tooltip">
        <label for="prompt">プロンプト</label>
        <!-- <span class="tooltip-icon" title="sample">?</span> -->
      </div>
      <input type="text" id="prompt" v-model="prompt" />
    </div>
    <div class="input-row">
      <div class="label-tooltip">
        <label for="negativePrompt">ネガティブプロンプト</label>
        <!-- <span class="tooltip-icon" title="sample">?</span> -->
      </div>
      <input type="text" id="negativePrompt" v-model="negativePrompt" />
    </div>
    <div class="input-row">
      <div class="label-tooltip">
        <label for="modelSelector">モデル選択</label>
        <!-- <span class="tooltip-icon" title="sample">?</span> -->
      </div>
      <div class="model-selector">
        <select
          id="modelSelector"
          v-model="selectedModel"
          @change="onModelChange"
        >
          <option value="sd-1-6">stable-diffusion-v1-6</option>
        </select>
      </div>
    </div>
    <div class="input-row inline">
      <div class="half-width">
        <div class="label-tooltip">
          <label for="aspectRatio">画角</label>
          <!-- <span class="tooltip-icon" title="sample">?</span> -->
        </div>
        <select id="aspectRatio" v-model="aspectRatio">
          <option value="768x768">768x768[1:1]</option>
          <option value="576x768">576x768[3:4]</option>
          <option value="512x768">512x768[2:3]</option>
          <option value="576x1024">576x1024[9:16]</option>
          <option value="768x576">768x576[4:3]</option>
          <option value="768x512">768x512[3:2]</option>
          <option value="1024x576">1024x576[16:9]</option>
        </select>
      </div>
      <div class="half-width">
        <div class="label-tooltip">
          <label for="imageCount">画像生成数</label>
          <!-- <span class="tooltip-icon" title="sample">?</span> -->
        </div>
        <input
          type="number"
          id="imageCount"
          v-model="imageCount"
          min="1"
          max="4"
        />
      </div>
    </div>
    <div class="button-row">
      <button
        class="generate-button"
        @click="generateImage"
        :disabled="!prompt && !negativePrompt"
      >
        生成
      </button>
    </div>
    <!-- Overlay for processing message -->
    <ProcessingOverlay :show="isProcessing" />
    <ErrorPopup
      :show="showErrorPopup"
      :message="errorMessage"
      @close="closeErrorPopup"
    />
  </div>
</template>

<script>
import ProcessingOverlay from '@/components/ProcessingOverlay.vue'
import ErrorPopup from '@/components/ErrorPopup.vue'
import HTTPService from '@/services/HTTPService.js'
import { cilImage } from '@coreui/icons'

export default {
  components: {
    ProcessingOverlay,
    ErrorPopup,
  },
  data() {
    return {
      cilImage,
      selectedImage: 0,
      images: [
        { isDefault: true, alt: 'Default Image 1' },
        { isDefault: true, alt: 'Default Image 2' },
        { isDefault: true, alt: 'Default Image 3' },
        { isDefault: true, alt: 'Default Image 3' },
      ],
      prompt: '',
      negativePrompt: '',
      selectedModel: 'sd-1-6',
      imageCount: 1,
      aspectRatio: '768x768',
      isProcessing: false,
      errorMessage: '',
      showErrorPopup: false,
    }
  },
  methods: {
    selectImage(index) {
      this.selectedImage = index
    },
    onModelChange() {
      console.log('Selected model:', this.selectedModel)
    },
    async generateImage() {
      // Validate prompt and imageCount
      if (!this.prompt || this.prompt.trim() === '') {
        this.showErrorPopup = true
        this.errorMessage = 'Prompt cannot be empty or null'
        return
      }

      if (this.imageCount < 1 || this.imageCount > 4) {
        this.showErrorPopup = true
        this.errorMessage = 'Image count must be between 1 and 4'
        return
      }

      const [dimensions] = this.aspectRatio.split('[')
      const [width, height] = dimensions.split('x')
      const payload = {
        prompt: this.prompt,
        negativePrompt: this.negativePrompt,
        selectedModel: this.selectedModel,
        imageCount: parseInt(this.imageCount) ?? 1,
        width: parseInt(width),
        height: parseInt(height),
      }

      this.isProcessing = true

      try {
        const response = await HTTPService.generate_image(payload)
        console.log(response.data)
        if (response.data.images && response.data.images.length > 0) {
          this.images = response.data.images.map((image, index) => ({
            isDefault: false,
            thumbnail: `data:image/png;base64,${image.base64}`,
            full: `data:image/png;base64,${image.base64}`,
            alt: `Generated Image ${index + 1}`,
          }))
          this.selectedImage = 0
        }
      } catch (error) {
        console.error('Error generating image:', error.response.data.error)
        if (error.response && error.response.data.error) {
          this.errorMessage = error.response.data.error
          this.showErrorPopup = true
        }
      } finally {
        this.isProcessing = false
      }
    },
    closeErrorPopup() {
      this.showErrorPopup = false
      this.errorMessage = ''
    },
  },
}
</script>

<style scoped>
.container {
  height: 100%;
  max-width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 3px solid #001d61;
  border-radius: 10px;
  background-color: rgb(240, 245, 245);
}

.selected-image-row,
.thumbnail-row,
.input-row,
.model-selector,
.button-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.selected-image {
  width: 220px;
  height: 220px;
  border-radius: 20px;
  overflow: hidden;
  margin: 0 auto;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(218, 223, 227);
  box-sizing: border-box;
}

.selected-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.thumbnail-row {
  justify-content: center;
  gap: 10px;
}

.thumbnail {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(218, 223, 227);
  box-sizing: border-box;
}

.thumbnail img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.thumbnail.selected {
  border: 2px solid #7aa9fa;
}

.input-row {
  flex-direction: column;
  text-align: left; /* Changed from center to left for better alignment */
}

.input-row.inline {
  flex-direction: row;
  align-items: center; /* Changed from flex-start to center */
  gap: 10px;
}

.label-tooltip {
  display: flex;
  /* align-items: center; */
  height: 30px;
}

.input-row label {
  font-size: 14px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  height: 100%;
}

.input-row input,
.input-row select,
.model-selector select {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  height: 50px;
}

.model-selector {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8);
}

.half-width {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.button-row {
  justify-content: center;
  margin-top: 10px;
}

.generate-button {
  padding: 10px 20px;
  background-color: #001d61;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.generate-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.generate-button:hover .upload-button:hover {
  background-color: rgb(20, 0, 236);
}

.tooltip-icon {
  cursor: pointer;
  border-radius: 50%;
  background-color: #ddd;
  width: 16px; /* Set a fixed width */
  height: 16px; /* Set a fixed height equal to the width */
  font-size: 10px;
  color: black;
  position: relative;
  display: inline-flex; /* Use flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.tooltip-icon:hover::after {
  content: attr(title);
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  margin-left: 10px;
}

/* Add a pseudo-element for the arrow */
.tooltip-icon:hover::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent #333 transparent transparent;
  margin-left: 5px;
}

.processing-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.processing-message {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.loading-icon-wrapper {
  margin-bottom: 10px;
}

.loading-icon {
  width: 50px;
  height: 50px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 400px; /* adjust the width as needed */
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
</style>
