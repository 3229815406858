<template>
  <div class="register-success-container">
    <CCard class="register-success-card">
      <CCardHeader>
        <h4 class="register-success-header-title">Registration Success</h4>
      </CCardHeader>
      <CCardBody class="register-success-card-body">
        <p class="register-success-card-text">
          Your registration has successfully been submitted.<br />
          An Automatic email has been sent to your registered email address.
          <br />
          Check your email and click on the Confirm link to complete your
          registration.
        </p>
        <CButton color="info" @click="returnHome"> Return to Home </CButton>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
export default {
  name: 'RegisterSuccess',
  methods: {
    returnHome() {
      //console.log('emmitting')
      this.$emit('change-active-component', {
        component: 'Login',
      })
    },
  },
}
</script>

<style>
.register-success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}
</style>
