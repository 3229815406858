<template>
  <div class="container">
    <div class="file-upload-container">
      <input
        type="file"
        ref="fileInput"
        @change="handleFileUpload"
        accept=".m4a,.mp3,.webm,.mp4,.mpga,.wav,.mpeg,.flac,.mov"
        style="display: none"
      />
      <div v-if="attachedFile" class="attached-file">
        <span>{{ attachedFile.name }}</span>
        <button class="remove-file" @click="removeFile">
          <CIcon :icon="cilX" />
        </button>
      </div>
    </div>
    <div class="generate-button-container">
      <button class="upload-button" @click="openFileBrowser">
        <CIcon :icon="cilPaperclip" />
      </button>
      <button
        class="generate-button"
        @click="transcibeAudio"
        :disabled="isProcessing || !attachedFile"
        :class="{ processing: isProcessing }"
      >
        生成
      </button>
    </div>
    <div class="timestamp-toggle">
      <label>
        <input type="checkbox" v-model="segmentTranscription" />
        Show timestamp
      </label>
    </div>
    <div class="chatbot-result">
      <div
        class="result"
        ref="chatbotResult"
        v-html="parseMarkdown(formattedTranscription)"
      ></div>
      <button
        class="copy-button"
        @click="copyContent"
        :disabled="formattedTranscription === ''"
      >
        <CIcon :icon="cilCopy" />
      </button>
    </div>
    <!-- Overlay for processing message -->
    <ProcessingOverlay :show="isProcessing" />
    <ErrorPopup
      :show="showErrorPopup"
      :message="errorMessage"
      @close="closeErrorPopup"
    />
  </div>
</template>

<script>
import ProcessingOverlay from '@/components/ProcessingOverlay.vue'
import ErrorPopup from '@/components/ErrorPopup.vue'
import HTTPService from '@/services/HTTPService.js'
import { marked } from 'marked'
import DOMPurify from 'isomorphic-dompurify'
import { cilSend, cilPaperclip, cilX, cilCopy } from '@coreui/icons'

export default {
  components: {
    ProcessingOverlay,
    ErrorPopup,
  },
  data() {
    return {
      cilSend,
      cilPaperclip,
      cilX,
      cilCopy,
      isProcessing: false,
      showErrorPopup: false,
      errorMessage: '',
      attachedFile: null,
      segmentTranscription: false,
      audioTranscription: { text: '', segments: [] },
    }
  },
  computed: {
    formattedTranscription() {
      if (
        this.segmentTranscription &&
        this.audioTranscription.segments.length > 0
      ) {
        return this.audioTranscription.segments
          .map((segment) => {
            return `[${this.formatTime(segment.start)} - ${this.formatTime(
              segment.end,
            )}] ${segment.text}`
          })
          .join('\\\n')
      } else {
        return this.audioTranscription.text
      }
    },
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0]
      console.log(file.type)
      if (file) {
        const allowedTypes = [
          'audio/mp3',
          'audio/wav',
          'audio/flac',
          'audio/m4a',
          'audio/x-m4a',
          'video/mp4',
          'video/avi',
          'video/quicktime',
          'video/webm',
          'video/mpeg',
          'audio/mpeg', // for mp3 file
          'audio/mpga',
          'video/x-matroska',
        ]
        if (allowedTypes.includes(file.type)) {
          this.attachedFile = file
        } else {
          this.showError(
            'Invalid file type. Please upload a supported audio or video file.',
          )
        }
      }
      this.$refs.fileInput.value = ''
    },

    removeFile() {
      this.attachedFile = null
    },

    openFileBrowser() {
      this.$refs.fileInput.click()
    },

    async transcibeAudio() {
      if (!this.attachedFile) {
        this.showError('Please upload an audio or video file first.')
        return
      }
      const formData = new FormData()
      formData.append('file', this.attachedFile)

      this.isProcessing = true
      try {
        const response = await HTTPService.transcribe_audio(formData)
        console.log(response.data)
        this.audioTranscription = {
          text: response.data.text,
          segments: response.data.segments,
        }
      } catch (error) {
        console.error('Error generating image:', error.response.data.error)
        if (error.response && error.response.data.error) {
          this.errorMessage = error.response.data.error
          this.showErrorPopup = true
        }
      } finally {
        this.isProcessing = false
      }
    },

    copyContent() {
      const copyText = this.formattedTranscription.replace(/\\\n/g, '\n')
      navigator.clipboard
        .writeText(copyText)
        .then(() => {
          console.log('Content copied successfully')
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err)
        })
    },

    showError(message) {
      this.errorMessage = message
      this.showErrorPopup = true
    },

    closeErrorPopup() {
      this.showErrorPopup = false
      this.errorMessage = ''
    },

    parseMarkdown(text) {
      if (typeof text !== 'string') {
        return '' // Return the text as is if it's not a string
      }

      text = text.replaceAll('\\n', '\n')
      text = text.replaceAll('```markdown', '```')
      try {
        const renderer = new marked.Renderer()
        renderer.link = function (href, title, text) {
          // Renderer() seems to return different parameters depending on the version of marked installed...
          const link = {
            href: href.href ?? href,
            text: href.text ?? text,
          }
          return (
            '<a target="_blank" href="' + link.href + '">' + link.text + '</a>'
          )
        }

        text = text.replaceAll('```', '')
        let parsed = marked(text, { renderer: renderer })

        // format Bing's source links more nicely
        // 1. replace "[^1^]" with "[1]" (during progress streams)
        parsed = parsed.replace(/\[\^(\d+)\^]/g, '<strong>[$1]</strong>')
        // 2. replace "^1^" with "[1]" (after the progress stream is done)
        parsed = parsed.replace(/\^(\d+)\^/g, '<strong>[$1]</strong>')

        // Allow the iframe to show the images created by Bing Image Creator.
        return DOMPurify.sanitize(parsed, {
          ADD_TAGS: ['iframe'],
          ADD_ATTR: [
            'allow',
            'allowfullscreen',
            'frameborder',
            'scrolling',
            'srcdoc',
            'target',
          ],
        })
      } catch (err) {
        console.error('ERROR', err)
        return null
      }
    },

    formatTime(timeValue) {
      // Convert the timeValue to seconds
      const totalSeconds = Math.floor(parseFloat(timeValue))

      if (isNaN(totalSeconds)) {
        console.error('Unable to parse time value:', timeValue) // Error log
        return '00:00:00' // Return a default value if parsing fails
      }

      const hours = Math.floor(totalSeconds / 3600)
      const minutes = Math.floor((totalSeconds % 3600) / 60)
      const seconds = totalSeconds % 60

      const pad = (num) => num.toString().padStart(2, '0')

      return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`
    },
  },
}
</script>

<style scoped>
.container {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border: 3px solid #001d61;
  border-radius: 10px;
  background-color: rgb(240, 245, 245);
}

.file-upload-container {
  width: 100%;
  margin-bottom: 20px;
}

.attached-file {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remove-file {
  background: none;
  border: none;
  color: #ff0000;
  cursor: pointer;
}

.generate-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.generate-button,
.upload-button {
  padding: 10px 10px;
  background-color: #001d61;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}

.generate-button {
  padding: 10px 20px;
}

.upload-button {
  padding: 10px 10px;
}

.generate-button:hover .upload-button:hover {
  background-color: #002b8f;
}

.generate-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.generate-button.processing {
  background-color: #808080;
  cursor: not-allowed;
}

.timestamp-toggle {
  align-self: flex-end;
  margin-top: 40px;
}

.timestamp-toggle label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.timestamp-toggle input[type='checkbox'] {
  margin-right: 5px;
}

.chatbot-result {
  width: 100%;
  height: 100%;
  border: 1px solid rgb(218, 223, 227);
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.chatbot-result .result {
  height: 100%;
  padding: 10px 15px;
  font-size: 14px;
  border: none;
  resize: none;
  overflow-y: auto;
  background-color: #fff;
  color: inherit;
  font-family: inherit;
}

.chatbot-result .result:focus {
  outline: none;
}

.processing-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.processing-message {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.loading-icon-wrapper {
  margin-bottom: 10px;
}

.loading-icon {
  width: 50px;
  height: 50px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.copy-button {
  position: absolute;
  bottom: 5px;
  right: 20px;
  padding: 5px 10px;
  background-color: none;
  border: 0px;
  border-radius: 3px;
  cursor: pointer;
}

.copy-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.copy-button:hover {
  background-color: #e0e0e0;
}
</style>
