<template>
  <div>
    <p
      style="
        text-align: center;
        font-size: 50px;
        color: #0c2231;
        font-weight: bold;
      "
    >
      We have various plans for your needs:
    </p>
  </div>
  <div class="row" style="display: flex">
    <div
      v-if="visibleLoggedIn == true && subscribed == false"
      style="margin-top: 20px; flex: 1; margin-left: 150px"
    >
      <div
        :class="[
          'PriceColumn',
          'flex-container',
          'direction-column',
          'justify-content-flex-start',
          'align-items-center',
        ]"
        :data-testid="price - column"
        :style="{
          '--pt-animate-in-order': 0,
          border: plan.name === user.plan ? '1px solid #0c2231' : 'none',
        }"
        v-for="plan in noplans"
        :key="plan.id"
      >
        <div
          class="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center"
        >
          <div class="PriceColumn-aboutProduct flex-container direction-column">
            <!-- <div class="PriceColumn-badge flex-container">
            <div
              class="Badge is-testModeBadge flex-container align-items-center"
            >
              <span
                class="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500"
                >{{ plan.name }}</span
              >
            </div>
          </div> -->
            <div class="PriceColumn-textAndProductImage flex-container">
              <div
                class="PriceColumn-textContainerHeightAligner"
                :style="{ height: 'auto' }"
              >
                <div
                  class="PriceColumn-textContainer flex-container direction-column"
                >
                  <div class="PriceColumn-name flex-container">
                    <span
                      class="Text Text-color--default Text-fontSize--20 Text-fontWeight--600"
                      style="color: black"
                      >{{ plan.name }}</span
                    >
                  </div>
                  <span
                    class="PriceColumn-description Text Text-color--default Text-fontSize--14"
                    style="color: black"
                    >{{ plan.description }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="PriceColumn-priceAndButton flex-container direction-column"
          >
            <div
              class="PriceColumn-priceContainerHeightAligner"
              :style="{ height: 'auto' }"
            >
              <div class="PriceColumn-priceContainer">
                <div
                  class="flex-container spacing-4 direction-column align-items-flex-start"
                >
                  <div class="flex-container align-items-center">
                    <span
                      class="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700"
                      style="color: black"
                      ><span>{{ plan.price }}</span></span
                    >
                    <!-- <span
                    class="PriceColumn-interval Text Text-color--default Text-fontSize--13"
                    style="color: black; opacity: 0.5"
                    >per <br />{{ plan.period }}</span
                  > -->

                    <span
                      class="PriceColumn-interval Text Text-color--default Text-fontSize--13"
                      style="color: black; opacity: 0.5"
                      >per <br />month/year</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <button
              class="Button PriceColumn-button Button--primary Button--lg"
              type="button"
              :style="{
                backgroundColor: 'rgb(0, 116, 212)',
                borderColor: 'rgb(0, 116, 212)',
              }"
              v-on:click="mailto('sales@chatbotanything.com')"
            >
              <div
                class="flex-container justify-content-center align-items-center"
              >
                <span class="Text Text-color--default Text-fontWeight--500">
                  Contact Sales
                </span>
              </div>
            </button>
          </div>
        </div>
        <div
          class="PriceColumn-featureListContainer flex-container direction-column"
        >
          <span
            class="PriceColumn-featureListHeader Text Text-color--default Text-fontSize--14"
            style="color: black"
            >This includes:</span
          >
          <div
            class="PriceColumn-featureList flex-container direction-column align-items-flex-start"
          >
            <div
              class="PriceColumn-feature flex-container align-items-flex-start"
            >
              <div class="PriceColumn-checkContainer flex-container">
                <svg
                  class="InlineSVG Icon PriceColumn-check Icon--sm"
                  focusable="false"
                  fill="#1a1a1a"
                  color="#1a1a1a"
                  fill-opacity="0.5"
                  height="16"
                  viewBox="0 0 16 16"
                  width="16"
                >
                  <path
                    d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <span
                class="PriceColumn-featureText Text Text-color--default Text-fontSize--14"
                style="color: black"
                >{{ plan.chatbots }} chatbots</span
              >
            </div>
            <div
              class="PriceColumn-feature flex-container align-items-flex-start"
            >
              <div class="PriceColumn-checkContainer flex-container">
                <svg
                  class="InlineSVG Icon PriceColumn-check Icon--sm"
                  focusable="false"
                  fill="#1a1a1a"
                  color="#1a1a1a"
                  fill-opacity="0.5"
                  height="16"
                  viewBox="0 0 16 16"
                  width="16"
                >
                  <path
                    d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <span
                class="PriceColumn-featureText Text Text-color--default Text-fontSize--14"
                style="color: black"
                >{{ plan.messages }}</span
              >
            </div>
            <div
              class="PriceColumn-feature flex-container align-items-flex-start"
            >
              <div class="PriceColumn-checkContainer flex-container">
                <svg
                  class="InlineSVG Icon PriceColumn-check Icon--sm"
                  focusable="false"
                  fill="#1a1a1a"
                  color="#1a1a1a"
                  fill-opacity="0.5"
                  height="16"
                  viewBox="0 0 16 16"
                  width="16"
                >
                  <path
                    d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <span
                class="PriceColumn-featureText Text Text-color--default Text-fontSize--14"
                style="color: black"
                >{{ plan.characters }}</span
              >
            </div>
            <div
              class="PriceColumn-feature flex-container align-items-flex-start"
              v-for="feature in plan.features"
              :key="feature"
            >
              <div class="PriceColumn-checkContainer flex-container">
                <svg
                  class="InlineSVG Icon PriceColumn-check Icon--sm"
                  focusable="false"
                  fill="#1a1a1a"
                  color="#1a1a1a"
                  fill-opacity="0.5"
                  height="16"
                  viewBox="0 0 16 16"
                  width="16"
                >
                  <path
                    d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <span
                class="PriceColumn-featureText Text Text-color--default Text-fontSize--14"
                style="color: black"
                >{{ feature }}</span
              >
            </div>

            <span
              class="PriceColumn-featureListHeader Text Text-color--default Text-fontSize--14"
              style="color: black"
            >
              For inquiries or to request a quote, please feel free to contact
              our sales team at
              <a v-on:click="mailto('sales@chatbotanything.com')" href="#"
                >sales@chatbotanything.com</a
              >
              .
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      id="kent"
      v-if="visibleLoggedIn == true && subscribed == false"
      style="flex: 6 1 75%"
    >
      <stripe-pricing-table
        pricing-table-id="prctbl_1MqxTkG7pVA9m7O45AYGgXmm"
        publishable-key="pk_test_51Mqq30G7pVA9m7O4b8NNE4H6ixx9JvHsPBrOBXMNwyJMDri3NkQceulceCrYLBBQZEdpMyJZOozO08i3wAm97lAt00bBzkO1pg"
        :client-reference-id="user.email"
        :customer-email="user.email"
        :disabled="true"
      ></stripe-pricing-table>
    </div>
  </div>
  <!-- <div>
    Test
    <main>
      <component :is="activeComponent"></component>
    </main>
  </div> -->
  <div
    v-if="visibleLoggedIn == false || subscribed == true"
    class="PriceColumnsContainer flex-container justify-content-space-between"
    style="
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
    "
  >
    <div
      :class="[
        'PriceColumn',
        'flex-container',
        'direction-column',
        'justify-content-flex-start',
        'align-items-center',
      ]"
      :data-testid="price - column"
      :style="{
        '--pt-animate-in-order': 0,
        border: plan.name === user.plan ? '1px solid #0c2231' : 'none',
      }"
      v-for="plan in plans"
      :key="plan.id"
    >
      <div
        class="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center"
      >
        <div class="PriceColumn-aboutProduct flex-container direction-column">
          <!-- <div class="PriceColumn-badge flex-container">
            <div
              class="Badge is-testModeBadge flex-container align-items-center"
            >
              <span
                class="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500"
                >{{ plan.name }}</span
              >
            </div>
          </div> -->
          <div class="PriceColumn-textAndProductImage flex-container">
            <div
              class="PriceColumn-textContainerHeightAligner"
              :style="{ height: 'auto' }"
            >
              <div
                class="PriceColumn-textContainer flex-container direction-column"
              >
                <div class="PriceColumn-name flex-container">
                  <span
                    class="Text Text-color--default Text-fontSize--20 Text-fontWeight--600"
                    style="color: black"
                    >{{ plan.name }}</span
                  >
                </div>
                <span
                  class="PriceColumn-description Text Text-color--default Text-fontSize--14"
                  style="color: black"
                  >{{ plan.description }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="PriceColumn-priceAndButton flex-container direction-column">
          <div
            class="PriceColumn-priceContainerHeightAligner"
            :style="{ height: 'auto' }"
          >
            <div class="PriceColumn-priceContainer">
              <div
                class="flex-container spacing-4 direction-column align-items-flex-start"
              >
                <div class="flex-container align-items-center">
                  <span
                    class="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700"
                    style="color: black"
                    ><span>{{ plan.price }}</span></span
                  >
                  <!-- <span
                    class="PriceColumn-interval Text Text-color--default Text-fontSize--13"
                    style="color: black; opacity: 0.5"
                    >per <br />{{ plan.period }}</span
                  > -->

                  <span
                    class="PriceColumn-interval Text Text-color--default Text-fontSize--13"
                    style="color: black; opacity: 0.5"
                    >per <br />month</span
                  >
                </div>
              </div>
            </div>
          </div>
          <button
            class="Button PriceColumn-button Button--primary Button--lg"
            type="button"
            :style="{
              backgroundColor: 'rgb(0, 116, 212)',
              borderColor: 'rgb(0, 116, 212)',
            }"
            v-on:click="onRegister(plan)"
          >
            <div
              v-if="plan.id === plans[4].id"
              class="flex-container justify-content-center align-items-center"
            >
              <span class="Text Text-color--default Text-fontWeight--500">
                Contact
              </span>
            </div>
            <div
              v-else
              class="flex-container justify-content-center align-items-center"
            >
              <span class="Text Text-color--default Text-fontWeight--500">
                {{ buttonLabel }}
              </span>
            </div>
          </button>
        </div>
      </div>
      <div
        class="PriceColumn-featureListContainer flex-container direction-column"
      >
        <span
          class="PriceColumn-featureListHeader Text Text-color--default Text-fontSize--14"
          style="color: black"
          >This includes:</span
        >
        <div
          class="PriceColumn-featureList flex-container direction-column align-items-flex-start"
        >
          <div
            class="PriceColumn-feature flex-container align-items-flex-start"
          >
            <div class="PriceColumn-checkContainer flex-container">
              <svg
                class="InlineSVG Icon PriceColumn-check Icon--sm"
                focusable="false"
                fill="#1a1a1a"
                color="#1a1a1a"
                fill-opacity="0.5"
                height="16"
                viewBox="0 0 16 16"
                width="16"
              >
                <path
                  d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </div>
            <span
              class="PriceColumn-featureText Text Text-color--default Text-fontSize--14"
              style="color: black"
              >{{ plan.chatbots }} chatbots</span
            >
          </div>
          <div
            class="PriceColumn-feature flex-container align-items-flex-start"
          >
            <div class="PriceColumn-checkContainer flex-container">
              <svg
                class="InlineSVG Icon PriceColumn-check Icon--sm"
                focusable="false"
                fill="#1a1a1a"
                color="#1a1a1a"
                fill-opacity="0.5"
                height="16"
                viewBox="0 0 16 16"
                width="16"
              >
                <path
                  d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </div>
            <span
              class="PriceColumn-featureText Text Text-color--default Text-fontSize--14"
              style="color: black"
              >{{ plan.messages }}</span
            >
          </div>
          <div
            class="PriceColumn-feature flex-container align-items-flex-start"
          >
            <div class="PriceColumn-checkContainer flex-container">
              <svg
                class="InlineSVG Icon PriceColumn-check Icon--sm"
                focusable="false"
                fill="#1a1a1a"
                color="#1a1a1a"
                fill-opacity="0.5"
                height="16"
                viewBox="0 0 16 16"
                width="16"
              >
                <path
                  d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </div>
            <span
              class="PriceColumn-featureText Text Text-color--default Text-fontSize--14"
              style="color: black"
              >{{ plan.characters }}</span
            >
          </div>
          <div
            class="PriceColumn-feature flex-container align-items-flex-start"
            v-for="feature in plan.features"
            :key="feature"
          >
            <div class="PriceColumn-checkContainer flex-container">
              <svg
                class="InlineSVG Icon PriceColumn-check Icon--sm"
                focusable="false"
                fill="#1a1a1a"
                color="#1a1a1a"
                fill-opacity="0.5"
                height="16"
                viewBox="0 0 16 16"
                width="16"
              >
                <path
                  d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </div>
            <span
              class="PriceColumn-featureText Text Text-color--default Text-fontSize--14"
              style="color: black"
              >{{ feature }}</span
            >
          </div>
          <div
            v-if="plan.id === plans[4].id"
            class="PriceColumn-featureListHeader Text Text-color--default Text-fontSize--14"
            style="color: black"
          >
            For inquiries or to request a quote, please feel free to contact our
            sales team at
            <a v-on:click="mailto('sales@chatbotanything.com')" href="#"
              >sales@chatbotanything.com</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HTTPService from '@/services/HTTPService'
import StorageService from '@/services/StorageService'
// import ChatbotHeader from './ChatbotHeader.vue'
// import FAQ from './FAQ.vue'
import { PLANS } from '@/services/HTTPService'
import { FREEPLANS } from '@/services/HTTPService'
import { CUSTOMPLANS } from '@/services/HTTPService'

export default {
  name: 'Subscription',
  data() {
    return {
      user: {},
      plans: PLANS,
      freeplans: FREEPLANS,
      customplans: CUSTOMPLANS,
      noplans: [],
      visibleLoggedIn: false,
      subscribed: false,
      // activeComponent: ChatbotHeader,
    }
  },
  // components: { ChatbotHeader },
  async created() {
    //console.log('getting user')
    HTTPService.getuser()
      .then((userResponse) => {
        this.user = userResponse.data.user
        //console.log('inside getuuser returned')
        //console.log(userResponse.data)
        //console.log(this.user)

        if (this.user.plan != 'Free') {
          this.subscribed = true
          //console.log('setting subscribed: ' + this.subscribed)
        }
      })
      .catch((error) => {
        console.error(error)
      })
    //console.log('usersssssssssssss')
    //console.log(this.user)
    //console.log(this.user.email)
    // const userResponse = await HTTPService.getuser()
    // // const userPlanResponse = await HTTPService.getplan('/userplan')

    // this.user = userResponse.data
    // // this.plan = userPlanResponse.data
    // console.log(this.user.email)
    //console.log('subscribed before: ' + this.subscribed)

    this.visibleLoggedIn = this.isLoggedIn()
  },
  computed: {
    buttonLabel() {
      return this.subscribed ? 'Manage' : 'Subscribe'
    },
  },
  methods: {
    mailto(email) {
      window.location.href = `mailto:${email}`
    },
    upgradePlan() {
      this.$router.push({ path: '/pricing' })
    },
    isLoggedIn() {
      // console.log(StorageService.getToken())
      // console.log(!StorageService.getToken())
      if (!StorageService.getToken()) {
        //console.log('returning false')
        return false
      } else {
        return true
      }
    },
    onRegister(plan) {
      //console.log(plan)
      if (plan.id === 'custom') {
        window.location.href = `mailto:sales@chatbotanything.com`
        return
      }
      //console.log('thissss onRegister')

      //console.log(this.isLoggedIn() && this.subscribed)

      if (this.isLoggedIn() && this.subscribed) {
        window.location.href =
          'https://billing.stripe.com/p/login/test_4gweVrgHed9Sbew288?prefilled_email=' +
          this.user.email
      } else {
        this.$emit('change-active-component', {
          component: 'Register',
        })
      }
      // this.$router.push('/chatbotmain')
      // this.$router.push({ path: '/chatbotmain', prop: { active: 'Register' } })
      //console.log('tesat')
      // this.$router.push({
      //   name: 'ChatbotMain',
      //   props: {
      //     active: 'ChatbotHome',
      //   },
      // })
    },
  },
}
</script>
<style scoped>
@import '../styles/pricing-table-app-a431a516672dbfeb84acc26ae33493d8.css';
</style>
