<template>
  <div class="container-fluid">
    <CCard class="faq">
      <CCardHeader>
        <h2>Frequently Asked Questions</h2>
      </CCardHeader>
      <CCardBody>
        <h4 class="question">What is Archaic Chatbot Anything?</h4>
        <p class="answer">
          Archaic Chatbot Anything is a platform that allows you to create
          ChatGPT chatbots from your own data. The created chatbot can answer
          questions and can be embedded on websites.
        </p>
        <h4 class="question">
          Does the chatbot answer questions that are not included in my data?
        </h4>
        <p class="answer">
          The Chatbot will try to find the answer from the provided data only.
          However, you can experiment by changing the Prompt in different ways
          to get different responses as you like.
        </p>
        <h4 class="question">What Data I can use?</h4>
        <p class="answer">Archaic Chatbot Anything supports:</p>
        <ul>
          <li>Word (.docx)</li>
          <li>Powerpoint (.pptx)</li>
          <li>Excel (.xlsx)</li>
          <li>HTML (.html)</li>
          <li>JPG (.jpg, .jpeg)</li>
          <li>Emails (.msg)</li>
        </ul>
        <ul>
          <li>Text (.txt)</li>
          <li>CSV (.csv)</li>
          <li>PDF (.pdf)</li>
          <li>Markdown (.md)</li>
          <li>Images (.png, .svg)</li>
        </ul>
        <br />
        <p>Various Audio and Youtube formats:</p>
        <ul>
          <li>M4A - Apple Lossless Audio Codec (.m4a)</li>
          <li>MP3 - MPEG Audio Layer III (.mp3)</li>
          <li>WEBM - WebM Audiovisual Media (.webm)</li>
          <li>MP4 - MPEG-4 Part 14 (.mp4)</li>
          <li>MPGA - MPEG Audio Stream (.mpga)</li>
          <li>WAV - Waveform Audio File Format (.wav)</li>
          <li>MPEG - Moving Picture Experts Group (.mpeg)</li>
          <li>MOV - QuickTime File Format (.mov)</li>
          <li>FLAC - Free Lossless Audio Codec (.flac)</li>
        </ul>
        <br />
        <h4 class="question">Can I add the Chatbot to my website?</h4>
        <p class="answer">
          Archaic Chatbot Anything was designed to help websites easily setup
          Chatbots on their site with minimal costs and set up time. After
          creating your Chatbot, you can change the settings to allow public
          access for the Chatbot and copy the provided URL to your Chatbot.
        </p>
        <h4 class="question">
          What Languages does Archaic Chatbot Anything support?
        </h4>
        <p class="answer">
          We support up to 50 Languages. Just upload in the data in the language
          of your choice, and update the Prompt with your own language.
        </p>
        <h4 class="question">How is my data stored on your servers?</h4>
        <p class="answer">
          We take the security and privacy of our users' data very seriously.
          All data is encrypted both in transit and at rest, and we use
          industry-standard security measures to ensure the confidentiality,
          integrity, and availability of your data. Our servers are located in
          secure data centers with restricted physical access.
        </p>
        <h4 class="question">Who has access to my data?</h4>
        <p class="answer">
          Access is strictly controlled and monitored. Data is encrypted, and
          our Team access is strictly controlled from accessing your data. We do
          not sell, rent, or share your data with any third parties, except as
          required by law or as necessary to provide our services to you.
        </p>

        <h4 class="question">What happens if there's a security breach?</h4>
        <p class="answer">
          In the unlikely event of a security breach, we will notify all
          affected users as soon as possible and take immediate steps to
          mitigate the impact of the breach. We will also cooperate fully with
          any law enforcement investigations and regulatory inquiries related to
          the breach.
        </p>

        <h4 class="question">Does Archaic Chatbot Anything have API?</h4>
        <p class="answer">
          Yes, API access is currently available for paid plans. Please refer to
          API Reference section. If you need help contact us:
          support@chatbotanything.com
        </p>
        <h4 class="question">Subscribing with Stripe:</h4>
        <p class="answer">
          Our platform uses Stripe as a payment gateway to handle subscriptions.
          To subscribe to a plan, you need to create an account and provide your
          billing information. Once you have subscribed to a plan, you will be
          charged on a recurring basis.
        </p>
        <h4 class="question">Plans and their use</h4>
        <p class="answer">
          We offer four plans: Free, Standard, Pro, and Enterprise. Each plan
          comes with different features, limits, and pricing. The plans are
          designed to meet different needs and budgets.
        </p>

        <h4 class="question">What plans are there?</h4>
        <p class="answer">
          We offer four plans: Free, Standard, Pro, and Enterprise.<br />
          Each plan comes with different features, limits, and pricing.<br />
          The plans are designed to meet different needs and budgets.
        </p>
        <h4 class="question">What details are there in each plan?</h4>
        <p class="answer">
          <strong>Free:</strong><br />
          Price: $0/ all time<br />
          Chatbots: 1<br />
          Messages: 5 messages / day<br />
          Characters: 1,000,000 characters (~1MB) / chatbot<br /><br />
          <strong>Standard:</strong><br />
          Price: $15/month<br />
          Chatbots: 10<br />
          Messages: 1000 messages / month<br />
          Characters: 3,000,000 characters (~3MB) / chatbot<br />
          Features: Install chatbot on website, API access<br /><br />

          <strong>Pro:</strong><br />
          Price: $50/month<br />
          Chatbots: 25<br />
          Messages: 3,000 messages / month<br />
          Characters: 5,000,000 characters (~ 5MB) / chatbot<br />
          Features: Install chatbot on website, API access<br /><br />

          <strong>Enterprise:</strong><br />
          Price: $250/month<br />
          Chatbots: 50<br />
          Messages: 250,000 messages / month<br />
          Characters: 10,000,000 characters (~ 10MB) / chatbot<br />
          Features: Install chatbot on website, API access
        </p>
        <h4 class="question">What are the Limits?</h4>
        <p class="answer">
          Messages: This limit defines the number of messages that a chatbot can
          process within a given period. If the limit is exceeded, the chatbot
          will stop processing messages until the limit resets at the start of
          the next period.
        </p>
        <p class="answer">
          Characters: This limit defines the number of characters that can be
          used in a chatbot's responses within a given period. If the limit is
          exceeded, the chatbot's responses will be truncated or blocked until
          the limit resets.
        </p>
        <p class="answer">
          Chatbots: This limit defines the number of chatbots that can be
          created under a single account. If the limit is exceeded, additional
          chatbots cannot be created until the limit is increased or a chatbot
          is deleted.
        </p>
        <p class="answer">
          These limits are set to ensure fair usage of the platform and to
          prevent overloading. Upgrading your plan can increase your limits and
          unlock additional features.
        </p>
        <h4 class="question">What happens if I exceed my plan's limits?</h4>
        <p class="answer">
          If you exceed your plan's limits, your chatbots may stop working until
          the limit resets at the beginning of the next period. For example, if
          you exceed your message limit for the month, your chatbot will no
          longer be able to process new messages until the next month begins.
        </p>
        <h4 class="question">Can I upgrade my plan to increase my limits?</h4>
        <p class="answer">
          Yes, you can upgrade your plan at any time to increase your limits and
          unlock additional features. Simply navigate to the billing section of
          your account and choose the plan that best fits your needs.
        </p>
        <h4 class="question">How do I subscribe to a plan using Stripe?</h4>
        <p class="answer">
          To subscribe to a plan using Stripe, simply navigate to the billing
          section of your account and select the plan you wish to subscribe to.
          You will then be prompted to enter your payment information and
          confirm your subscription. Once confirmed, you will be subscribed to
          the plan and your account limits will be updated accordingly.
        </p>
        <h4 class="question">What features are included with each plan?</h4>
        <p class="answer">
          The Free plan includes the ability to install a chatbot on your
          website and one chatbot with a limit of 6 messages per day and 100,000
          characters per bot.
        </p>
        <p class="answer">
          The Standard plan includes the ability to install a chatbot on your
          website, API access, and 15 chatbots with a limit of 600 messages per
          month and 1,000,000 characters per bot.
        </p>
        <p class="answer">
          The Pro plan includes the same features as the Standard plan, but with
          25 chatbots and a limit of 3,000 messages per month and 3,000,000
          characters per bot.
        </p>
        <p class="answer">
          The Enterprise plan includes the same features as the Pro plan, but
          with 50 chatbots and a limit of 250,000 messages per month and
          5,000,000 characters per bot.
        </p>
        <h4 class="question">What is API access?</h4>
        <p class="answer">
          API access refers to the ability to use the platform's API to
          integrate chatbots with other applications and services. With API
          access, users can create custom integrations and automate workflows
          between their chatbots and other tools.
        </p>
        <h4 class="question">How do I upgrade my plan?</h4>
        <p class="answer">
          To upgrade your plan, log in to your account and navigate to the
          "Plans" page. From there, select the plan you would like to upgrade to
          and follow the prompts to complete the upgrade process. Your new plan
          will be effective immediately, and any additional fees will be
          prorated based on the number of days remaining in your current billing
          period.
        </p>
        <h4 class="question">How do I cancel my subscription?</h4>
        <p class="answer">
          To cancel your subscription, log in to your account and navigate to
          the "Plans" page. From there, select the "Cancel Subscription" option
          and follow the prompts to confirm your cancellation. Please note that
          any chatbots created under your account will be deleted after the
          cancellation takes effect.
        </p>
        <h4 class="question">How does the platform handle personal data?</h4>
        <p class="answer">
          We take the handling of personal data very seriously and comply with
          all relevant data protection laws. We only collect and process the
          personal data that is necessary to provide our services and we never
          sell or share your personal data with third parties. We also use
          appropriate technical and organizational measures to protect your
          personal data from unauthorized access, disclosure, alteration, and
          destruction.
        </p>
        <h4 class="question">
          What kind of personal data does the platform collect?
        </h4>
        <p class="answer">
          We only collect the personal data that is necessary to provide our
          services, which includes your name, email address, and payment
          information if you subscribe to one of our paid plans. We may also
          collect data about how you use our platform to help us improve our
          services.
        </p>
        <h4 class="question">How is personal data stored?</h4>
        <p class="answer">
          All personal data is stored securely on our servers, which are located
          in Japan. We use industry-standard encryption and other security
          measures to protect your personal data.
        </p>
        <h4 class="question">
          Can I request to access, modify, or delete my personal data?
        </h4>
        <p class="answer">
          Yes, you have the right to access, modify, and delete your personal
          data at any time. You can do this by logging into your account and
          making the necessary changes. If you have any issues or questions,
          please contact our customer support team for assistance.
        </p>
        <h4 class="question">
          What happens to my personal data if I cancel my account?
        </h4>
        <p class="answer">
          If you cancel your account, we will delete all of your personal data
          within a reasonable amount of time. Please note that we may need to
          retain certain data for legal or administrative purposes, such as to
          comply with tax or accounting requirements.
        </p>
      </CCardBody>
    </CCard>

    <!-- <iframe
      src="http://localhost:8080/chatbot/2ace600e-7186-4988-9531-801eb8baf8b3"
      frameborder="0"
      style="
        position: fixed;
        bottom: 20px;
        right: 20px;
        width: 400px;
        height: 580px;
      "
    ></iframe> -->
  </div>
</template>
<script>
// import { CCardTitle } from '@coreui/vue'

export default {
  name: 'HomePage',
  data() {
    return {}
  },
  components: {},
}
</script>
<style scoped>
.jumbotron {
  background-color: #f8f9fa;
  padding: 50px;
}

.card-header {
  background-color: #f8f9fa;
  font-weight: 700;
  font-size: 22px;
}

.card-body {
  font-size: 18px;
}

.card {
  margin-bottom: 30px;
  border: none;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

ul {
  padding-left: 20px;
  margin-bottom: 0;
}

h1,
h2,
h4 {
  font-weight: 700;
  margin-bottom: 20px;
}

.container-fluid {
  padding: 30px;
}
</style>
