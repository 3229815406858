<template>
  <div v-if="show" class="processing-overlay">
    <div class="processing-message">
      <div class="loading-icon-wrapper">
        <img
          src="/images/loading.gif"
          alt="Processing..."
          class="loading-icon"
        />
      </div>
      <div>{{ message || 'Processing... Please wait!' }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProcessingOverlay',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
.processing-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.processing-message {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.loading-icon-wrapper {
  margin-bottom: 10px;
}

.loading-icon {
  width: 50px;
  height: 50px;
}
</style>
