<template>
  <div>
    <CContainer>
      <h1
        class="text-center"
        style="font-size: 50px; color: #0c2231; font-weight: bold"
      >
        Your Subscription Plan
      </h1>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4" v-if="plan.name">
            <CCardHeader
              ><strong>Plan Name:</strong> {{ plan.name }}</CCardHeader
            >
            <CCardBody class="p-4">
              <div v-if="plan">
                <p>
                  <strong>Monthly message limit:</strong>
                  {{ formattedMessages }}
                </p>
                <p>
                  <strong>Messages remaining:</strong> {{ formattedCredit }}
                </p>
                <p>
                  <strong>Maximum number of Chatbots allowed:</strong>
                  {{ plan.chatbots }}
                </p>
                <p>
                  <strong>Number of chatbots used:</strong> {{ user.chatbots }}
                </p>
                <p>
                  <strong>Remaining number of Chatbots allowed:</strong>
                  {{ plan.chatbots - user.chatbots }} /
                  {{ plan.chatbots }}
                </p>
                <p>
                  <strong>Maximum character limit per chatbot:</strong>
                  {{ formattedCharacters }}
                </p>
                <p v-if="plan.startTime">
                  <strong>Plan Period:</strong> {{ plan.startTime }} -
                  {{ plan.endTime }}
                </p>
              </div>
              <div
                v-if="plan.name != 'Enterprise'"
                class="d-flex justify-content-between"
              >
                <div style="font-weight: bold">
                  If you want to increase your limits, Upgrade Now:
                </div>
                <div>
                  <CButton color="info" @click="upgradePlan"
                    >Upgrade Plan</CButton
                  >
                </div>
              </div>
              <div v-else class="d-flex justify-content-between">
                <div>
                  <CButton color="info" @click="upgradePlan"
                    >Manage Plan</CButton
                  >
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import HTTPService from '@/services/HTTPService'
import { stripe } from 'stripe'

export default {
  name: 'Subscription',
  data() {
    return {
      user: {},
      plan: {},
    }
  },
  async created() {
    this.plan = {}
    HTTPService.getuser()
      .then((userResponse) => {
        //console.log('got response:')
        //console.log(userResponse.data)
        this.user = userResponse.data.user
        //console.log(this.user.plan)
        //console.log('test2')
        //console.log(userResponse.data.user.plan)
        return HTTPService.getplan(this.user.plan)
      })
      .then((plan) => {
        this.plan = plan.data
        //console.log(this.plan)
        //console.log(this.user.subscription_date)
        // Calculate plan period based on user subscription date
        if (this.user.subscription_date) {
          const startDate = new Date(this.user.subscription_date)
          const endDate = new Date(startDate)
          endDate.setMonth(endDate.getMonth() + 1)
          const startTimeString = startDate.toLocaleDateString()
          const endTimeString = endDate.toLocaleDateString()
          this.plan.startTime = startTimeString
          this.plan.endTime = endTimeString
        }
      })
      .catch((error) => {
        //console.log(error)
        if (error.response && error.response.status === 401) {
          this.$emit('change-active-component', {
            component: 'Login',
          })
        }
      })
  },
  methods: {
    upgradePlan() {
      window.location.href =
        'https://billing.stripe.com/p/login/test_4gweVrgHed9Sbew288?prefilled_email=' +
        this.user.email
    },
    async cancelSubscription() {
      try {
        const { data } = await HTTPService.cancelSubscription()
        if (data && data.success) {
          const subscriptionId = this.user.subscription_id
          const cancelResult = await stripe.subscriptions.del(subscriptionId)
          console.log('Cancel Result:', cancelResult)
          alert('Subscription cancelled successfully')
        }
      } catch (error) {
        console.log('Error cancelling subscription:', error)
        alert('Failed to cancel subscription. Please try again later.')
      }
    },
  },
  computed: {
    formattedCredit() {
      return this.user.credit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    formattedMessages() {
      return this.plan.messages.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    formattedChatbots() {
      return this.plan.chatbots.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    formattedCharacters() {
      return this.plan.characters
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>
