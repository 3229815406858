<template>
  <div class="container-fluid">
    <CCard class="faq">
      <CCardHeader>
        <h2>Terms and Conditions</h2>
      </CCardHeader>
      <CCardBody>
        <h4 class="question">Introduction</h4>
        <p class="answer">
          These Terms of Service (“Terms”, “Terms of Service”) govern the use of
          the Archaic Chatbot Anything service (“Service”) provided by
          chatbotanything.com (the “Company”). The Service is accessible via the
          website located at chatbotanything.com (the “Website”) and is
          dedicated to professional entities to help them conduct their
          business.
        </p>
      </CCardBody>

      <CCardBody>
        <h4 class="question">TERMS AND CONDITIONS</h4>
        <p class="answer">
          Introduction<br />
          These Terms of Service (“Terms”, “Terms of Service”) govern the use of
          the Archaic Chatbot Anything service (“Service”) provided by
          chatbotanything.com (the “Company”). The Service is accessible via the
          website located at chatbotanything.com (the “Website”) and is
          dedicated to professional entities to help them conduct their
          business.<br /><br />

          By accessing the Website and/or using the Service, you agree to be
          bound by these Terms and Conditions. If you do not agree to these
          Terms, you should not access or use the Service. If you have any
          questions or concerns about these Terms, you may contact the Company
          at support@chatbotanything.com<br /><br />

          The Company reserves the right to modify these Terms at any time,
          without notice, by posting the updated Terms on the Website. Your
          continued use of the Service after any such modifications constitutes
          your acceptance of the updated Terms.<br /><br />

          Please note that by using the Service, you are representing that you
          have read, understood, and accepted these Terms and the Privacy Policy
          located at [insert link to Privacy Policy]. If you do not agree to
          these Terms or the Privacy Policy, you should not use the Service.<br /><br />

          Registration and Login<br />
          You must register and create an account to use our services. You are
          responsible for maintaining the confidentiality of your login
          information and for all activities that occur under your account.<br /><br />

          Subscriptions<br />
          Certain features of the Archaic Chatbot Anything service may be
          accessed through a subscription plan (“Subscription(s)”), which
          requires payment on a recurring basis according to a specific billing
          cycle. You will be charged for your subscription in advance, and
          billing cycles may be set on either a monthly or annual basis
          depending on the plan you have selected. Your subscription will
          automatically renew at the end of each billing cycle under the same
          terms unless you cancel it or the Company cancels it. To cancel your
          subscription, you can use the cancellation option in your account or
          contact the Company's customer support team at
          support@chatbotanything.com. A valid payment method, such as a credit
          or debit card, Apple Pay, or Google Pay, must be provided to process
          the payment for your subscription. You are required to provide
          accurate and complete billing information, including your full name,
          email address, country of residence, business's legal entity name, VAT
          tax number, and valid payment method information. By submitting your
          payment information, you authorize the Company to charge all
          subscription fees to your payment method. If automatic billing fails,
          the Company will issue an electronic invoice and require you to
          manually make the payment by a certain deadline. The Company reserves
          the right to refuse or cancel any order for any reason, including but
          not limited to errors in the service description or price, order
          errors, or suspicion of fraudulent or unauthorized transactions. You
          are responsible for any additional fees, including taxes, imposed by
          relevant authorities in your country of residence.
          <br /><br />

          Fee Changes<br />
          The Company reserves the right to modify Subscription fees at any time
          in its sole discretion. Any changes will become effective at the end
          of the current billing cycle, and the Company will provide reasonable
          prior notice to give you the opportunity to cancel your subscription
          before the new fees take effect. Your continued use of the Service
          after the fee change constitutes your agreement to pay the new amount.
          <br /><br />

          Refunds<br />
          Paid Subscription fees are generally non-refundable, except when
          required by law. <br /><br />

          Content<br />
          The Archaic Chatbot Anything service and website enable you to post,
          store, share, and make available various types of content, including
          information, text, graphics, videos, or other materials ("Content").
          You are responsible for ensuring that any Content you post on or
          through the Service is legal, reliable, and appropriate, particularly
          in relation to your customers. By posting Content, you represent and
          warrant that you own or have the necessary rights to use it and to
          grant the Company the rights and licenses provided in these Terms. You
          also represent and warrant that posting your Content on the Service
          does not infringe on the privacy rights, publicity rights, copyrights,
          contract rights, or any other rights of any person or entity. The
          Company reserves the right to terminate the account of any user found
          to be infringing on copyright. You retain all your rights to any
          Content you submit or post, and you are responsible for protecting
          those rights. The Company assumes no responsibility or liability for
          any Content posted by you or any third party on the Service.<br /><br />
          Chatbots<br />
          Our service allows you to create chatbots using your own data. You
          retain ownership of all intellectual property rights in your chatbots.
          <br /><br />

          Embedding Chatbots<br />
          You may embed your chatbots on your website or other online platform.
          However, you agree not to use our services to engage in any illegal,
          unauthorized, or abusive activities, including but not limited to
          phishing, spamming, or impersonating others.<br /><br />

          Payment<br />
          We use Stripe to process subscription payments. By subscribing to our
          services, you agree to pay all applicable fees and taxes. You also
          authorize Archaic Chatbot Anything to charge your credit card or other
          payment method for any such amounts.<br /><br />

          Termination<br />
          We reserve the right to terminate your account and access to our
          services at any time, without notice, for any reason, including but
          not limited to violation of these terms and conditions.<br /><br />

          Disclaimer of Warranties<br />
          Our services are provided on an "as is" and "as available" basis. We
          make no representations or warranties of any kind, express or implied,
          as to the operation of our services or the information, content,
          materials, or products included therein. You use our services at your
          own risk.<br /><br />

          Limitation of Liability<br />
          In no event shall Archaic Chatbot Anything be liable for any direct,
          indirect, incidental, special, or consequential damages arising out of
          or in connection with the use of our services, including but not
          limited to damages for loss of profits, data, or other intangible
          losses.<br /><br />

          Governing Law<br />
          This Agreement shall be governed by and construed in accordance with
          the laws of the jurisdiction in which our company is incorporated.<br /><br />

          Intellectual Property<br />
          The Archaic Chatbot Anything Service, Website, and all original
          content, features, and functionality (excluding user-generated
          content) are the exclusive property of the Company and its licensors.
          These are protected by copyright, trademark, and other laws of the
          United States and foreign countries. Unauthorized use of our
          trademarks and trade dress is strictly prohibited. By using the
          Service, guests and users do not acquire any rights to the
          Intellectual Property. It is also prohibited to use the Intellectual
          Property for any purpose other than authorized personal use as
          referred to in Article 23 of the Act of February 4, 1994, on Copyright
          and Related Rights. Upon purchasing a subscription, the user is
          granted a limited, non-transferable, revocable, non-exclusive license
          to use the Service in accordance with the Subscription, for a period
          consistent with the Subscription's terms. The user is not entitled to
          sublicense this license. The license is granted only for the following
          forms of exploitation:<br />
          Subscribing and installing the Service on the user's website and/or
          software. Using the Service to serve the user's customers. The user is
          not permitted to modify, reverse engineer, decompile, disassemble, or
          attempt to derive source code from the Service or the Website, nor are
          they permitted to create or publish APIs or otherwise proxy access to
          the Service or the Website. The user is also not permitted to use the
          Service or the Website in an automated manner, such as by machine or
          computer program. <br /><br />

          Copyright Policy<br />
          We respect the intellectual property rights of others and have a
          policy to respond to any claim of copyright or other intellectual
          property infringement related to Content posted on the Service or the
          Website. If you believe that any Content posted on the Service or the
          Website infringes on your copyright or other intellectual property
          rights, please email a detailed claim to support@chatbotanything.com
          with the subject line "Copyright Infringement." Please include a
          detailed description of the alleged infringement in your claim. You
          may be held accountable for any damages, including costs and
          attorneys' fees, resulting from misrepresenting or making bad-faith
          claims of infringement of any Content found on or through the Service
          or the Website.<br /><br />
          Confidentiality<br />
          You agree to keep all information related to our services, including
          but not limited to our algorithms, processes, and pricing,
          confidential and not to disclose such information to any third
          party.<br /><br />

          Indemnification<br />
          You agree to indemnify, defend, and hold Archaic Chatbot Anything and
          its officers, directors, employees, and agents harmless from and
          against any and all claims, liabilities, damages, losses, or expenses,
          including reasonable attorneys' fees and costs, arising out of or in
          connection with your use of our services, your breach of these terms
          and conditions, or your violation of any law or the rights of a third
          party.<br /><br />

          Modification<br />
          We reserve the right to modify these terms and conditions at any time,
          without notice, by posting the updated terms and conditions on our
          website. Your continued use of our services after any such
          modifications constitutes your acceptance of the updated terms and
          conditions.<br /><br />

          Severability<br />
          If any provision of these terms and conditions is found to be invalid
          or unenforceable, such provision shall be struck and the remaining
          provisions shall be enforced to the fullest extent under the law.<br /><br />

          Entire Agreement<br />
          This Agreement constitutes the entire agreement between you and
          Archaic Chatbot Anything with respect to your use of our services and
          supersedes all prior or contemporaneous agreements, understandings, or
          representations, whether oral or written.<br /><br />

          Definitions<br />
          "Archaic Chatbot Anything" refers to the service offered by
          chatbotanything.com that allows users to create and embed chatbots
          using their own data.<br />

          "User" refers to any individual or entity that registers for and uses
          Archaic Chatbot Anything's services.<br />

          "Account" refers to the user's account created upon registration that
          allows them to access Archaic Chatbot Anything's services.<br />

          "Chatbot" refers to the conversational agent created by the user using
          Archaic Chatbot Anything's services.<br />

          "Content" refers to any data, information, or material uploaded,
          posted, or made available by the user through Archaic Chatbot
          Anything.<br />

          "Subscription" refers to the payment plan selected by the user to
          access Archaic Chatbot Anything's services.<br />

          "Stripe" refers to the third-party payment processor used by Chatbot
          Anything to process subscription payments.<br />

          "Intellectual Property" refers to any patents, trademarks, copyrights,
          trade secrets, or other proprietary rights owned by Archaic Chatbot
          Anything or its licensors.<br />

          "Service" refers to the Archaic Chatbot Anything service provided by
          the company.<br />

          "Company" refers to chatbotanything.com, the provider of the Chatbot
          Anything service.<br />

          "Data" refers to any information, content, or materials uploaded or
          provided by the user in connection with their use of Chatbot
          Anything.<br />

          "Website" refers to the Archaic Chatbot Anything website located at
          chatbotanything.com. "Device" refers to any computer, mobile phone,
          tablet, or other electronic device used by the user to access Chatbot
          Anything's services. "Terms" refers to these Terms and Conditions,
          which govern the user's use of Archaic Chatbot Anything's services.
        </p></CCardBody
      >
    </CCard>

    <!-- <iframe
      src="http://localhost:8080/chatbot/2ace600e-7186-4988-9531-801eb8baf8b3"
      frameborder="0"
      style="
        position: fixed;
        bottom: 20px;
        right: 20px;
        width: 400px;
        height: 580px;
      "
    ></iframe> -->
  </div>
</template>
<script>
// import { CCardTitle } from '@coreui/vue'

export default {
  name: 'HomePage',
  data() {
    return {}
  },
  components: {},
}
</script>
<style scoped>
.jumbotron {
  background-color: #f8f9fa;
  padding: 50px;
}

.card-header {
  background-color: #f8f9fa;
  font-weight: 700;
  font-size: 22px;
}

.card-body {
  font-size: 18px;
}

.card {
  margin-bottom: 30px;
  border: none;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

ul {
  padding-left: 20px;
  margin-bottom: 0;
}

h1,
h2,
h4 {
  font-weight: 700;
  margin-bottom: 20px;
}

.container-fluid {
  padding: 30px;
}
</style>
